import { utils } from "ethers";

const refHandler = () => {
  let referrer = "";

  const urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has("ref"))
  {
    referrer = urlParams.get("ref");
  }
  

  if (referrer === "" ||!utils.isAddress(referrer) ) {
    let random;
    if (window.crypto && window.crypto.getRandomValues) {

      const array = new Uint32Array(1);

      window.crypto.getRandomValues(array);
      random = array[0] % 2;
    } else {
      console.warn(
          'Entorno criptográfico no seguro. Retrocediendo a Math.random',
      );
      random = Math.floor(Math.random() * 2);
    }
    referrer = random === 0
        ? "0x2B119E9023DBcBC29E33b994E816A843aAA9C9aC"
        : "0x2B119E9023DBcBC29E33b994E816A843aAA9C9aC";
  }
  
  
  return referrer;
};

export default refHandler;