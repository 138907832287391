import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import InvestNow from "../components/InvestNow";

import Admin from "../components/Admin";

const AppRouter = () => {


  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          iconTheme: {
            primary: "#9F0",
            secondary: "#000",
          },
        }}
      />

      <div
          style={{ backgroundImage: `url(/bg.png)`}}
          className='bg-[#540070] lg:bg-top bg-center bg-no-repeat   bg-cover bg-fixed'>
        <Routes>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

          <Route path='/' element={<InvestNow />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </div>
    </>
  );
};
export default AppRouter;
