import DistributtionSocios from './DistributtionSocios';
import React from 'react';

function Admin() {
  const wallet ="0x1cf51138798bcc76dc9209f8f1ec2ef2cf899a64"
  return (
    <div className='py-16'>
     

           <div className="mx-auto max-w-4xl overflow-hidden px-4 pt-7">
            <h1 className='text-center text-4xl text-white py-8'>ADMIN only token </h1>
            <h3 className='text-center text-sm text-white py-1'>SEND TOKEN OR BNB TO </h3>
            <p className='text-center text-sm text-white py-8'>{wallet}</p>
           <DistributtionSocios
        contractAddress={wallet}
        />

           </div>

    </div>
  );
}

export default Admin;
