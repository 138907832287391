import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "./index.css";
import App from "./App";
import { Web3Provider } from "./context/Web3Context";
import { TokenProvider } from "./context/TokenHandle";
import { SpinnerProvider } from "./context/SpinnerContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SpinnerProvider>
        <ToastProvider autoDismiss autoDismissTimeout={2000}>
          <Web3Provider>
          
          
            <TokenProvider>
              <App />
            </TokenProvider>
          
          
          </Web3Provider>
        </ToastProvider>
      </SpinnerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
