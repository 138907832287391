import React, { useContext, useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import TokenHandle from '../context/TokenHandle';
import clsx from 'clsx';
import { utils } from 'ethers';
import refHandle from '../hooks/utils';
import Modal from './Modal';

function Stake() {
  const [amount, setamount] = useState(0.01);
  const [referred, setReferred] = useState('');
  const [errorPrice, setErrorPrice] = useState('');
  const [ModalOpen, setModalOpen] = useState(false);
  const { invest, userData, allData, isApprove, approveHandle } =
    useContext(TokenHandle);

  useEffect(() => {
    const referred_ = refHandle();
    if (referred_ != '0x373Ea14E600A474F30fc27b39dAd0253d3094dcf')
      setReferred(referred_);

  }, []);

  useEffect(() => {
    setErrorPrice('');
    if (amount < 23.4) setErrorPrice(`The minimum invest is 23.4 BILL`);
  }, [amount]);

  const balance = parseFloat(userData.balance_) || 0;
  const totalWithdrawn_ = parseFloat(userData.totalWithdrawn_) || 0;
  const totalDeposits = parseFloat(userData.totalDeposits_) || 0;
  const bonusWithdraw_c = parseFloat(userData.bonusWithdrawC) || 0;

  let progressPercentage;

  if (totalDeposits === 0) {
    progressPercentage = 0;
  } else if (
    !isNaN(balance) &&
    !isNaN(totalWithdrawn_) &&
    !isNaN(bonusWithdraw_c)
  ) {
    progressPercentage =
      ((totalWithdrawn_ + balance - bonusWithdraw_c) * 100) / totalDeposits;
  } else {
    progressPercentage = 0;
  }

  console.log(referred,"referred")

  return (
      <>

        <div className="  ">
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex-1 space-y-8">
                {/* Stake */}
                <div className="mt-8  mx-auto  max-w-7xl ">
                  <div
                      className="card-container flex lg:flex-row lg:space-y-0 space-y-6 flex-col items-center space-x-3 px-4 py-8  w-full shadow sm:px-10">
                    <div className={`lg:py-7`}>
                      <img src={"/21bills.png"} className="w-40 h-auto"/>
                    </div>
                    <div className="w-full lg:py-7">
                      <div className="mt-1">
                        <div>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div
                                className="pointer-events-none absolute inset-y-0 lg:right-8 right-5 flex items-center pl-3 text-white">
                              BILLS
                            </div>
                            <input
                                onChange={(e) => {
                                  if (e.target.value == "" || e.target.value == 0)
                                    setamount("23.4");
                                  else setamount(e.target.value);
                                }}
                                className={clsx(
                                    "rounded-md",
                                    {
                                      "border-red-300 text-center text-red-500 placeholder-red-300 focus:ring-red-500 focus:border-red-500 flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50":
                                      errorPrice,
                                    },
                                    {
                                      "text-center font-bold flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-white text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50":
                                          !errorPrice,
                                    },
                                )}
                                id="stake"
                                name="stake"
                                type="number"
                                required
                            />
                          </div>
                        </div>

                        {errorPrice && (
                            <>
                              <div className="absolute mt-2 w-60">
                                <p
                                    className="text-xs text-red-300"
                                    id="price-error"
                                >
                                  {errorPrice}
                                </p>
                              </div>
                            </>
                        )}
                      </div>
                    </div>
                    <div className="lg:w-auto w-full lg:py-7">
                      {isApprove ? (
                          <button
                              onClick={() => invest(amount, referred)}
                              className="flex hover:brightness-110 justify-center w-full btn-green  py-3 lg:px-12 text-md px-1 rounded-md"
                          >
                            INVEST
                          </button>
                      ) : (
                          <button
                              onClick={approveHandle}
                              type="button"
                              className="flex hover:brightness-110 justify-center w-full btn-green  py-3 lg:px-12 px-1 text-md rounded-md"
                          >
                            Approve
                          </button>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 card-container ">
                    <div className="mt-1">
                      <h2
                          htmlFor="referred"
                          className="block mb-1 ml-1 text-sm uppercase font-bold text-white text-center"
                      >
                        Referred
                      </h2>
                      <input
                          onChange={(e) => {
                            setReferred(e.target.value);
                          }}
                          className="block w-full px-3 py-2  text-xs font-bold text-center text-[#9F0] bg-[#540070] rounded-md shadow-sm appearance-none   sm:text-xs"
                          id="referred"
                          name="referred"
                          type="text"
                          required
                          readOnly
                          defaultValue={referred === "0x2B119E9023DBcBC29E33b994E816A843aAA9C9aC" ? "0xc415e580C8228b7de825F1253FEFf5f9558435c2" : referred}
                      />
                    </div>
                  </div>
                </div>

                <div
                    className=" card-container relative    flex justify-center items-center px-6 py-5  rounded-lg shadow-sm ">
                  <h3 className="lg:text-xl mt-1 font-black text-[#9F0] uppercase text-center">
                    Users: <span className="lg:inline block">{allData.totalUsers_}</span>
                  </h3>
                </div>

                {/*Statitics */}

                <div className="card-container relative flex items-center px-6 py-5 space-x-3  rounded-lg shadow-sm ">
                  <div className="flex-1 min-w-0">
                    <div className="lg:w-96 w-full">
                      <p className="mt-2 break-all text-[#99FF00]">
                        {progressPercentage <= 200
                            ? `Progreso: ${progressPercentage.toFixed(2)}%`
                            : "You have reached the goal!"}
                      </p>
                      <div className="mt-4">
                        <div className="mt-1 h-2.5 relative overflow-hidden bg-white rounded-full">
                          <div
                              className="h-2.5 rounded-full bg-[#99FF00]"
                              style={{
                                // Ensure the width does not exceed 100% of the container
                                width: `${Math.min(progressPercentage / 2, 100)}%`,
                              }}
                          />
                        </div>
                      </div>
                    </div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      <div className="overflow-hidden rounded-lg bg-gradient-purple px-4 py-3 shadow ">
                        <dt className="truncate text-sm font-medium text-[#9F0]">
                          Total Deposit
                        </dt>
                        <dd className="mt-1 text-xl font-montserrat font-semibold tracking-tight text-gray-100">
                          {userData.totalDeposits_} BILLS
                        </dd>
                      </div>
                      <div className="overflow-hidden rounded-lg  px-4 py-3 shadow bg-gradient-purple">
                        <dt className="truncate text-sm font-medium text-[#9F0]">
                          Your Balance
                        </dt>
                        <dd className="mt-1 text-xl font-montserrat font-semibold tracking-tight text-gray-100">
                          {userData.balance_} BILLS
                        </dd>
                      </div>
                      <div className="overflow-hidden rounded-lg  px-4 py-3 shadow bg-gradient-purple">
                        <dt className="truncate text-sm font-medium text-[#9F0]">
                          Total Withdraw
                        </dt>
                        <dd className="mt-1 text-xl font-montserrat font-semibold tracking-tight text-gray-100">
                          {userData.totalWithdrawn_} BILLS
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default Stake;
