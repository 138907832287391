import { useMemo, useContext, useState } from "react";
import { Bnbi, BUSD, claimAbi, saleAbi, saleAbi2 } from "./abiHelpers";
import Web3Context from "../context/Web3Context";
import { Contract, ethers } from "ethers";
import {useWeb3ModalProvider} from "@web3modal/ethers5/react";

export const address = {
  bnbi: "0xc08d2Eb7d9c3cd4CAA635FFBA755daD0E70ebfb5",
  busd: "0xfbc7460d6644192c483d0f291241928b60f09c08",
  privateSale:"0x03C66EB15880681364C5C47583C6B1B8e93B0230",
  publicSale:"0x2125B46FF616d1227E89C3530f7CFf8D397aa2Ff",  
  newPresale: "0xfadac4129991bd599dce557826f7e14e4fd5ce15",
  claim:"0x114A22e5eD8AbB010abDb58191E9946225f6fbA7"

};

export const useBUSD = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { accounts, isLoaded } = useContext(Web3Context);
  return useMemo(async () => {
    if (!isLoaded) return [false, null];
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new Contract(address.busd, BUSD, signer);
      return [true, contract];
    } catch (e) {
      console.log(e);
      return [false, null];
    }
  }, [accounts, isLoaded]);
};



export const  usePrivateSale =async ()=>{
  const { accounts, isLoaded } = useContext(Web3Context);
  const { walletProvider } = useWeb3ModalProvider();
  return useMemo(async() => {
    
    if(!isLoaded){
    console.log("not loaded");
    return [false,null]}
    try{

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
    const contract = new Contract(address.privateSale ,saleAbi,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}
export const usePublicSale = ()=>{
  const {accounts, isLoaded} = useContext(Web3Context)
  const { walletProvider } = useWeb3ModalProvider();
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    try{
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const contract = new Contract(address.publicSale ,saleAbi2,signer)
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}

export const usePublicSaleNew = ()=>{
  const {accounts, isLoaded} = useContext(Web3Context)
  const { walletProvider } = useWeb3ModalProvider();
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    try{
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
    const contract = new Contract(address.newPresale ,saleAbi2,signer)          
    return [true, contract]
    }catch(e){
      console.log(e)
      return [false,null]
    }
    },[accounts,isLoaded])
}


export const useBnbi = () => {
  const { accounts, isLoaded } = useContext(Web3Context);
  const { walletProvider } = useWeb3ModalProvider();
  return useMemo(async () => {
    if (!isLoaded) return [false, null];
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    console.log(address.bnbi, "address.bnbi");
    const contract = new ethers.Contract(address.bnbi, Bnbi, signer);
    return [true, contract];
  }, [accounts, isLoaded]);
};

export const useClaim = () => {
  const { accounts, isLoaded } = useContext(Web3Context);
  const { walletProvider } = useWeb3ModalProvider();
  return useMemo(async () => {
    if (!isLoaded) return [false, null];
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    // console.log(address.bnbi, "address.bnbi");
    const contract = new ethers.Contract(address.claim, claimAbi, signer);
    return [true, contract];
  }, [accounts, isLoaded]);
};

const etherJSProvider = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  return provider;
};
