import Input from "./Forms";
import clsx from "clsx";
import { utils, Contract, ethers, constants } from "ethers";
import React, { useRef, useState, useContext, useEffect, useMemo } from "react";
import Web3Context from "../context/Web3Context";
import TokenHandle from "../context/TokenHandle";
// import {  abidis } from "../hooks/abiHelpers";
import { address as address_, useBUSD } from "../hooks/useContracts";
import { toast } from "react-hot-toast";

export const useContract = (address_) => {
  const { accounts, isLoaded, connect } = useContext(Web3Context);
  return useMemo(async () => {
    if (!isLoaded) return null;
    try {
      const provider = await connect();
      const signer = provider.getSigner();
      const address =
        address_ == undefined
          ? "0x8816e9a7B6dc8894f20590BFA66322D1678342ed"
          : address_;
      const contract = new Contract(address, `abidis`, signer);
      return contract;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, [accounts, isLoaded]);
};

function DistributtionSocios({ contractAddress, isBio }) {
  const { accounts, isLoaded, connect } = useContext(Web3Context);
  const {
    userData,
    withdraw,

    reinvestment,
    balanceOf,
    allData,
    invest,
  } = useContext(TokenHandle);

  const COntract = useContract(contractAddress);
  const [wallet_, setWallet] = useState("");
  const [percentage_, setPercentage] = useState(0);
  const [showPercentage, setShowPercentage] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0);
  const [totalbnb, setTotalbnb] = useState(0);
  const [update_, setupdate_] = useState(0);
  const [isApprove, setisApprove] = useState(false);
  const [currenAmount, setCurrenAmount] = useState(0);

  useEffect(() => {
    if (!isLoaded) return;
    submit();
    getBalanceToken();
    getBalance();
    getIsAdmin();
    allowanceHandle();
    return () => {};
  }, [isLoaded, accounts, update_]);

  const precenDivider = 10000;
  const [IsAdmin, setsetIsAdmin] = useState(true);
  const royaltiesRef = useRef(null);
  const [error, setError] = useState({
    title: false,
    collection: false,
    copies: false,
    content: false,
    author: false,
    royaltiesDistribution: false,
    royaltiesPercentage: false,
  });
  const [royaltiesDistributionDefauld, setRoyaltiesDistributionDefauld] =
    useState(null);
  const [royaltiesDistribution, setRoyaltiesDistribution] = useState([
    {
      address: "0x0000000",
      percentage: 100,
      isdev: false,
    },
  ]);

  const updatePercentageArray = async () => {
    console.log("test");
    try {
      // console.log(royaltiesDistribution);
      const percentage = royaltiesDistribution
        .map((wallet) => Number(wallet.percentage))
        .reduce((a, b) => Number(a) + Number(b), 0);

      if (percentage > 10000)
        return alert("The sum of the percentages cannot be greater than 100%");
      const newWallets = royaltiesDistribution
        .map((wallet, i) => {
          if (
            !utils.isAddress(wallet.wallet) &&
            wallet.wallet == royaltiesDistributionDefauld[i].wallet &&
            wallet.percentage == royaltiesDistributionDefauld[i].percentage
          )
            return null;
          else
            return {
              wallet: wallet.address,
              percentage: wallet.percentage,
            };
        })
        .filter((wallet) => wallet != null);
      if (newWallets.length == 0)
        return alert("No changes were made or invalid address");

      const contract = await COntract;

      const res = await contract.updatePercentageArray(
        newWallets.map((wallet) => wallet.wallet),
        newWallets.map((wallet) => wallet.percentage)
      );
      res.wait(1).then((res) => {
        submit();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message.split('reason="')[1].split('",')[0]);
    }
  };

  const getIsAdmin = async () => {
    try {
      if (!ethers.utils.isAddress(accounts)) return;
      const contract = await COntract;
      const Admin = await contract.ADMIN();
      console.log(Admin, "Admin");
      const res = await contract.hasRole(Admin, accounts);
      console.log(res, "res");
      setsetIsAdmin(res);
    } catch (error) {
      // alert(error)
      console.log(error);
    }
  };
  const Token = useBUSD();

  const approveHandle = async (type) => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    if (!load) return;
    const addr = contractAddress;
    const res = await contract.approve(addr, constants.MaxUint256);
    res.wait().then(() => setupdate_(update_ + 1));
  };

  const allowanceHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    if (!load) return;
    const allowance_ = await contract.allowance(accounts, contractAddress);
    console.log(allowance_.gt(constants.MaxUint256.div(5)), "allowance_");
    setisApprove(allowance_.gt(constants.MaxUint256.div(5)));
  };

  const distributeBalanceToken = async () => {
    try {
      const contract = await COntract;
      const token_ = address_.busd;
      const res = await contract.distributeBalanceTokenPlus(token_);
      res.wait(2).then((res) => {
        submit();
        getBalanceToken();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message.split('reason="')[1].split('",')[0]);
    }
  };

  const distributeBalanceTokenETH = async () => {
    try {
      const contract = await COntract;
      const amount = ethers.utils.parseEther(currenAmount);

      console.log(amount, "amount");
      // return
      //  (await contract.provider.getBalance(accounts)).sub(ethers.utils.parseEther("0.1"));
      const res = await contract.distributeBalanceETH({
        value: amount,
      });
      res.wait(2).then((res) => {
        submit();
        getBalanceToken();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message.split('reason="')[1].split('",')[0]);
    }
  };

  const getBalanceToken = async () => {
    try {
      const contract = await COntract;
      const res = await contract.getBalanceToken();
      setTotalMoney(utils.formatEther(res));
    } catch (error) {
      console.log(error, "getBalanceToken");
    }
  };

  const getBalance = async () => {
    try {
      const contract = await COntract;
      // const res = await contract.getBalance();
      const res = await contract.provider.getBalance(accounts);
      setTotalbnb(utils.formatEther(res));
    } catch (error) {
      console.log(error, "getBalanceToken");
    }
  };

  const addWalletHandler = async () => {
    try {
      if (!utils.isAddress(wallet_)) return alert("Invalid address");
      const percentage = royaltiesDistribution
        .map((wallet) => Number(wallet.percentage))
        .reduce((a, b) => Number(a) + Number(b), 0);
      if (percentage + Number(percentage_) > 10000)
        return alert("The sum of the percentages cannot be greater than 100%");
      if (royaltiesDistribution.find((wallet) => wallet.address == wallet_))
        return alert("This address already exists");

      const contract = await COntract;
      const res = await contract.addWallet(wallet_, percentage_);
      res.wait(2).then((res) => {
        submit();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message.split('reason="')[1].split('",')[0]);
    }
  };
  const deleteWallet = async (index) => {
    try {
      const { address, isdev } = royaltiesDistribution[index];
      if (isdev) return alert("You cannot delete this address");

      const contract = await COntract;
      const res = await contract.removeWallet(address);
      res.wait(2).then((res) => {
        submit();
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message.split('reason="')[1].split('",')[0]);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  const removeWallet = (index) => {
    const { percentage } = royaltiesDistribution[index];
    const filteredWallets = JSON.parse(
      JSON.stringify(royaltiesDistribution.filter((u, i) => i !== index))
    );
    filteredWallets[0].percentage += percentage;
    setRoyaltiesDistribution(filteredWallets);
  };

  const editWallet = (e, index) => {
    const { value } = e.target;
    const wallets = JSON.parse(JSON.stringify(royaltiesDistribution));
    wallets[index].address = value;
    setRoyaltiesDistribution(wallets);
  };

  const editPercentage = (e, index) => {
    const reg = /^[0-9\b]+$/;
    const value = Number(e.target.value);

    if (value >= 0) {
      let wallets = JSON.parse(JSON.stringify(royaltiesDistribution));
      wallets[index].percentage += wallets[index].percentage - value;

      console.log(value);
      wallets[index].percentage = value;
      wallets[index].showPercentage = value / 100;
      setRoyaltiesDistribution(wallets);
    } else e.target.value = value;
  };

  const submit = async (e) => {
    const contract = await COntract;
    console.log(contract, "contract");
    // return

    let royaltiesDistribution_ = await contract.getWalletsAndPercentages();

    // console.log(royaltiesDistribution_.length, 'royaltiesDistribution_');
    royaltiesDistribution_ = royaltiesDistribution_.map((wallet) => {
      const isdev = wallet.wallet == address_.devWallet;
      return {
        address: wallet.wallet,
        percentage: wallet.percentage.toString(),
        showPercentage: Number(wallet.percentage.toString()) / 100,
        isdev,
      };
    });
    console.log(royaltiesDistribution_);
    setRoyaltiesDistribution(royaltiesDistribution_);
    setRoyaltiesDistributionDefauld(royaltiesDistribution_);
  };

  return (
    <div
      className={clsx(
        "rounded-lg bg-slate-800 shadow-b bg-zyberGrey border border-gray-100 bg-cover   bg-local bg-no-repeat py-8"
      )}
    >
      <h1 className='tlt relative mt-6 text-center text-4xl uppercase text-white'>
        Distributtion Wallet
      </h1>

      <fieldset className='flex w-full -space-x-1 px-6 lg:px-12'>
        <Input
          onChange={(e) => {
            setWallet(e.target.value);
          }}
          className=' '
          onlyRead
        />
        <Input
          type='number'
          name='percentage'
          id='percentage'
          placeholder='0'
          max='1000'
          onChange={(e) => {
            setPercentage(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          width='w-[100px]'
          className={clsx("rounded-tl-none rounded-bl-none", {
            " border-red-300  text-red-900  placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
              error.royaltiesPercentage && percentage_ === 0,
          })}
          errorMessage='Percentage must be greater than 0.'
        />
        <div className='relative'>
          <Input
            type='text'
            name='percentage'
            id='percentage'
            placeholder='0%'
            onlyRead
            width='w-[80px]'
            value={percentage_ / 100}
            className={clsx("rounded-tl-none rounded-bl-none")}
            errorMessage='Percentage must be greater than 0.'
          />
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-white'>
            %
          </div>
        </div>
      </fieldset>
      <div className='flex justify-center'>
        <button
          type='button'
          onClick={() => addWalletHandler()}
          className='mt-2 mb-12 bg-blue-600 inline-flex uppercase  items-center px-8 py-3 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-slate-900 focus:ring-offset-2 '
        >
          Add another Wallet
        </button>
      </div>

      <div className='mx-auto mt-2 space-y-2 lg:px-7 px-2'>
        {royaltiesDistribution.map((wallet, index) => {
          let total = 0;
          let percentage = 0;
          if (index == royaltiesDistribution.length - 1) {
            total =
              royaltiesDistribution
                .map((wallet) => Number(wallet.percentage))
                .reduce((a, b) => Number(a) + Number(b), 0) +
              Number(percentage);
            percentage = total / 100;
          }

          return (
            <>
              <div key={index} className='flex items-center -space-x-0'>
                {/* TODO: change for the Button IconOnly component */}

                <button
                  type='button'
                  className='mr-2 inline-flex items-center rounded-lg border border-white px-2 pb-2 pt-3 text-lg font-medium text-white hover:text-white focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-2'
                  onClick={() => deleteWallet(index)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='h-4 w-4'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                    />
                  </svg>
                </button>
                <fieldset className='flex w-full -space-x-1'>
                  <Input
                    type='text'
                    ref={royaltiesRef}
                    name={`wallet-address-${index}`}
                    id={`wallet-address-${index}`}
                    full
                    placeholder='Wallet address'
                    disable={wallet.isdev}
                    value={wallet.address}
                    onChange={(e) => {
                      if (wallet.isdev) return;
                      editWallet(e, index);
                      if (error.royaltiesDistribution) {
                        setError({
                          ...error,
                          royaltiesDistribution: false,
                        });
                      }
                    }}
                    error={
                      (error.royaltiesDistribution &&
                        !utils.isAddress(wallet.address)) ||
                      (error.royaltiesPercentage && wallet.percentage < 1)
                    }
                    errorMessage={
                      (error.royaltiesPercentage &&
                        "Percentage must be greater than 0.") ||
                      (error.royaltiesDistribution && "Invalid wallet address.")
                    }
                    className='rounded-tr-none rounded-br-none '
                  />
                  <Input
                    type='number'
                    name={`percentage-${index}`}
                    id={`percentage-${index}`}
                    placeholder='0%'
                    max='1000'
                    value={wallet.percentage}
                    onChange={(e) => {
                      if (wallet.isdev) return;
                      editPercentage(e, index);
                      if (error.royaltiesPercentage) {
                        setError({
                          ...error,
                          royaltiesPercentage: false,
                        });
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    width='w-[100px]'
                    className={clsx("rounded-tl-none rounded-bl-none", {
                      " border-red-300  text-red-900  placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
                        error.royaltiesPercentage && wallet.percentage === 0,
                    })}
                    errorMessage='Percentage must be greater than 0.'
                  />
                  <Input
                    type='text'
                    name={`percentage32-${index}`}
                    id={`percentage32-${index}`}
                    placeholder='0%'
                    value={wallet.showPercentage}
                    disable={true}
                    width='w-[60px]'
                    className={clsx("rounded-tl-none rounded-bl-none", {
                      "border-red-300  text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500":
                        error.royaltiesPercentage && wallet.percentage === 0,
                    })}
                    errorMessage='Percentage must be greater than 0.'
                  />
                </fieldset>
              </div>

              {index == royaltiesDistribution.length - 1 ? (
                <div className=' block w-full'>
                  <fieldset className='flex w-full -space-x-1'>
                    <Input className=' ' onlyRead>
                      1
                    </Input>
                    <Input
                      className={clsx(
                        "rounded-tl-none rounded-bl-none",
                        total > 10000
                          ? " border-red-300  text-red-900  placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                          : ""
                      )}
                      width='w-[100px]'
                      onlyRead
                      value={total}
                    />
                    <Input
                      className={clsx(
                        "rounded-tl-none rounded-bl-none",
                        percentage > 100
                          ? " border-red-300  text-red-900  placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
                          : ""
                      )}
                      onlyRead
                      width='w-[60px]'
                      value={percentage}
                    />
                  </fieldset>
                </div>
              ) : null}
            </>
          );
        })}
        <dl className='mt-5 grid grid-cols-1 divide-y divide-gray-600 overflow-hidden rounded-lg bg-[#1a1a1a] shadow md:grid-cols-2 md:divide-x md:divide-y-0'>
          <div className='px-4 py-5 sm:p-6'>
            <dt className='text-base font-normal text-gray-100'>
              DINERO TOTAL A REPARTIR
            </dt>
            <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
              <div className='flex items-baseline text-2xl font-semibold text-yellow-400'>
                {totalbnb} BNB
              </div>
            </dd>
          </div>
          <div className='px-4 py-5 sm:p-6'>
            <dt className='text-base font-normal text-gray-100'>
              DINERO TOTAL A REPARTIR:
            </dt>
            <dd className='mt-1 flex items-baseline justify-between md:block lg:flex'>
              <div className='flex items-baseline text-2xl font-semibold text-white'>
                {balanceOf} TOKEN
              </div>
            </dd>
          </div>
        </dl>
        <div className='pt-6'>
          <label
            htmlFor='price'
            className='block text-sm font-medium leading-6 text-gray-100'
          >
            DINERO TOTAL A REPARTIR:
          </label>
          <div className='relative mt-2 rounded-md shadow-sm'>
            <input
              type='number'
              name='price'
              id='price'
              className='block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              placeholder='0.00'
              value={currenAmount}
              onChange={(e) => setCurrenAmount(e.target.value)}
              aria-describedby='price-currency'
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <span
                className='text-gray-700 sm:text-sm'
                id='price-currency'
              ></span>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 pt-4'>
          {isApprove ? (
            <button
              type='button'
              onClick={() => distributeBalanceToken()}
              className='mt-6  inline-flex items-center justify-center whitespace-nowrap rounded-md text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 '
            >
              REPARTIR DINERO TOKEN
            </button>
          ) : (
            <button
              type='button'
              onClick={() => approveHandle()}
              className='  inline-flex items-center justify-center whitespace-nowrap rounded-md text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2'
            >
              APPROVE DINERO TOKEN
            </button>
          )}
          <button
            type='button'
            onClick={() => distributeBalanceTokenETH()}
            className=' inline-flex items-center justify-center whitespace-nowrap rounded-md text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2'
          >
            REPARTIR DINERO BNB
          </button>

          <button
            type='button'
            onClick={() => submit()}
            className=' inline-flex items-center justify-center whitespace-nowrap rounded-md text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2'
          >
            GET WALLET ADDRESS
          </button>
          <button
            type='button'
            onClick={() => updatePercentageArray()}
            className=' inline-flex items-center justify-center whitespace-nowrap rounded-md text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2'
          >
            update Percentage
          </button>
        </div>
      </div>
    </div>
  );
}

export default DistributtionSocios;
