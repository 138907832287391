import { useMemo, useContext, useState } from 'react';
import { abi, BUSD, saleAbi, masterchefv2Abi, StakeAbi, abiContract } from './abiHelpers';
import Web3Context from '../context/Web3Context';
import { BigNumber, Contract, ethers } from 'ethers';
import refHandler from './utils';
import { address } from './useContracts';

export const UseBillboost = (_address=address.bnbi) => {
  const { accounts, isLoaded, connect } = useContext(Web3Context);
  

  const contract_ = async () => {
    if (!isLoaded) return null;
    const provider = await connect();
    const signer = provider.getSigner();
    const contract = new Contract(_address, abiContract, signer);
    return contract;
  };

  const invest = async (investAmt) => {
    try {
      
    
    const contract = await contract_();
    if (!contract) return;
    investAmt = ethers.utils.parseEther(investAmt);
    const ref =  refHandler();
    const tx =  contract.invest(ref, investAmt);
    return tx;
  } catch (error) {
   console.log(error);
   
      
  }
  }

  const withdraw_f = async () => {
    try {
      
    
    const contract = await contract_();
    if (!contract) return;
    const tx =  contract.withdraw_f();
    return tx;
  } catch (error) {
   console.log(error);
      
  }
  }
  
  const withdraw_C = async () => {
    try {
      
    
    const contract = await contract_();
    if (!contract) return;
    const tx =  contract.withdraw_C();
    return tx;
  } catch (error) {
   console.log(error);
      
  }
  }
  


const getPublicData = async () => {
  try {
    const contract = await contract_();
    if (!contract) return;
    const tx =  contract.getPublicData();
    return tx;
  }
  catch (error) {
    console.log(error);
  }
}

const getUserData = async (_user) => {
  try {
    const contract = await contract_();
    if (!contract) return;
    const user_ = _user || accounts;
    const tx =  contract.getUserData(_user);
    return tx;
  }
  catch (error) {
    console.log(error);
  }
}

const getUserMachineBalance = async (_user) => {
  try {
    const contract = await contract_();
    if (!contract) return;
    const user_ = _user || accounts;
    const tx =  contract.getUserMachineBalance(_user);
    return tx;
  }
  catch (error) {
    console.log(error);
  }
}
    
    

  const getUser2 = async (_user) => {
    try {
      const contract = await contract_();
      if (!contract) return;
      const user_ = _user || accounts;
      const tx =  contract.getUser2(_user);
      return tx;
    }
    catch (error) {
      console.log(error);
    }
  }

  const getUser = async (_user) => {
    try {
      const contract = await contract_();
      if (!contract) return;
      const user_ = _user || accounts;
      const tx =  contract.getUser(_user);
      return tx;
    }
    catch (error) {
      console.log(error);
    }
  }

const WITHDRAW_FEE_PERCENT = async (date) => {
  try {
    const contract = await contract_();
    if (!contract) return;
    const tx =  contract.WITHDRAW_FEE_PERCENT(date);
    return tx;
  }
  catch (error) {
    console.log(error);
  }
  
}

const getMaxTimeWithdrawByUser = async (_user) => {
  try {
    const contract = await contract_();
    if (!contract) return;
    const user_ = _user || accounts;
    const tx =  contract.getMaxTimeWithdrawByUser(_user);
    return tx;
  }
  catch (error) {
    console.log(error);
  }
}
  const data ={
    contract_,
    invest,
    withdraw_f,
    withdraw_C,
    getPublicData,
    getUserData,
    getUserMachineBalance,
    getUser2,
    getUser,
    WITHDRAW_FEE_PERCENT ,
    getMaxTimeWithdrawByUser

  }
  return useMemo(() => {

    try {
      return data
      
    } catch (e) {
      console.log(e);
      return data;
    }
  }, [accounts, isLoaded]);
};