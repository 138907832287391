import React, { useContext, useState, useEffect } from 'react';
import CardRef from './CardRef';
import Stake from './Stake';
import TokenHandle from '../context/TokenHandle';
import ModalInfoCompesated from './ModalInfoCompesated';
import ModalWithdrawFrequent from './ModalWithdrawFrequent';
import ModalTableReferral from './ModalTableReferral';
import { useCountdown } from '../hooks/useCountdown';
import Banner from './Banner';
import Navbar from './Navbar';
import PriceToken from './PriceToken';
import { formatRange } from '../utils/utils';
import { toast } from 'react-hot-toast';
import Web3Context from '../context/Web3Context';
import { ethers } from 'ethers';
import { useClaim } from '../hooks/useContracts';
import { motion } from "framer-motion";
import {ChevronDownIcon} from "@heroicons/react/solid";
import clsx from "clsx";
function InvestNow() {
  const { withdraw, reinvestment, userData, withdraw2, allData } =
    useContext(TokenHandle);
  const { accounts } = useContext(Web3Context);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const [dashboardTotal, setDashboardTotal] = useState([
    {
      name: "Deposit",
      value: 0,
    },
    {
      name: "Bonus",
      value: 0,
    },
    {
      name: "Withdraw",
      value: 0,
    },
    {
      name: "Reinvest",
      value: 0,
    },
    // More people...
  ]);
  useEffect(() => {
    // console.log(userData, "userData");
    const dashboardTotal_ = [
      {
        name: "Deposit",
        value: userData.totalDeposits_,
      },
      {
        name: "Withdraw",
        value: userData.totalWithdrawn_,
      },
      {
        name: "Bonus",
        value: userData.machineBalance,
      },
      // More people...
    ];
    setDashboardTotal(dashboardTotal_);

    return () => {};
  }, [userData]);

  const { timerDays, timerHours, timerMinutes, timerSeconds } = useCountdown([
    2023, 12, 1, 18,
  ]);
  useEffect(() => {
    return () => {};
  }, [timerDays]);

  const DAI_ADDRESS = "0xfbc7460d6644192c483d0f291241928b60f09c08";
  const DAI_SYMBOL = "BILLS";
  const DAI_DECIMALS = 18;
  const DAI_IMAGE = "https://www.21billboost.com/bill.png";

  async function connectMetaMask() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      } catch (error) {
        console.error("El usuario rechazó la conexión", error);
      }
    } else {
      alert("Por favor instala MetaMask primero.");
    }
  }

  async function addToMetaMask() {
    try {
      // Primero intenta conectar con MetaMask.
      await connectMetaMask();

      // Luego intenta agregar el token.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: DAI_ADDRESS,
            symbol: DAI_SYMBOL,
            decimals: DAI_DECIMALS,
            image: DAI_IMAGE,
          },
        },
      });

      if (wasAdded) {
        toast.success("Token agregado a MetaMask");
      } else {
        toast.success("El token ya estaba agregado");
      }
    } catch (error) {
      console.error("Hubo un error al agregar el token a MetaMask", error);
    }
  }

  function isInControlPanel(address) {
    const res = [
      "0xce1449506Ca5189D4e8Aa7dd5E7DbCf0BED67346",
      "0xb64A8aD1B5c499b71754a2Ee87e550169255967a",
      "0x987d466d0774F387A1C658d0B313622328C754F7",
      "0x7799D81769B91DFa7380ec58F6AD2527273503A6",
    ].some((item) => item?.toLowerCase() === address?.toLowerCase());
    console.log("ControlPanel", address, res);
    return res;
  }

  return (
    <>
      <Navbar />
      <div className="flex items-center space-x-4 mx-auto max-w-6xl lg:pt-60 pt-40 px-4  divide-x-2 divide-[#99FF00]">
        {/* Placeholder for the image */}
        <img src="/21.png" alt="21 Logo" className="h-12 w-12 " />

        <div className="flex flex-col pl-2">
          <div className="flex items-center space-x-2  pb-1 ">
            <h1 className="text-[#99FF00] font-bold lg:text-lg text-[0.62rem]">
              PRECIO DEL BILLS
            </h1>
          </div>
          <span className="text-white ont-bold lg:text-md text-xs ">
            <PriceToken /> USD
          </span>
        </div>
      </div>
      <div className="relative mx-auto max-w-6xl">
        <img alt="" className="w-full pt-20 animate-bob" src="/money-1.png" />
        <img
          alt=""
          className="absolute animate-money lg:top-[45%] top-[63%] left-[15%] w-[62%] lg:left-[17%] lg:w-[55%] transform translate-y-[10%] lg:translate-y-[0] animate-fadeIn"
          src="/money-2.png"
        />
      </div>
      <h2 className="text-center lg:mt-5 mt-12 lg:text-lg text-xs text-white px-4 mx-auto max-w-3xl mb-12">
        21Bills es un pool de liquidez donde las personas pueden aportar desde
        23.1 Bills en adelante y ganar Bills todos los días.
      </h2>
      <div className="px-3 w-full max-w-6xl mx-auto">
        <div className="card-container lg:mx-auto max-w-6xl mt-2 ">
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 items-center"
          >
            <li className="col-span-1 mx-auto">
              <img
                className={`lg:w-full w-56`}
                alt={``}
                src={"/bills-usdt.png"}
              />
            </li>
            <li className="col-span-1 mx-auto">
              <img
                className={`lg:w-full w-56`}
                alt={``}
                src={"/bills-bnb.png"}
              />
            </li>
            <li className="col-span-1 mx-auto">
              <img
                className={`lg:w-full w-56`}
                alt={``}
                src={"/bills-21.png"}
              />
            </li>
          </ul>
        </div>
        <div
          className={clsx("bg-[#310042] px-2 pb-2", {
            "rounded-t-lg rounded-full": !isOpen,
            "rounded-md": isOpen,
          })}
        >
          <div className="cursor-pointer"
              onClick={toggleOpen}
          >
            <button

              className="bg-gradient-custom text-white text-md py-2 px-4 rounded-full rounded-t-lg w-full"
            >
              CONOCE NUESTRO ECOSISTEMA CRYPTO
            </button>
            <div
              className={clsx("flex justify-center -mb-4", {
                hidden: isOpen,
              })}
            >
              <ChevronDownIcon className="w-20 h-12 text-white -mt-2" />
            </div>
          </div>

          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <EcosystemImage />
          </motion.div>
        </div>
      </div>

      <div id="dashboard" className=" w-full max-w-6xl mx-auto px-3">
        <Stake />
        <div className="mt-5 card-container relative flex items-center px-6 py-5 space-x-3  rounded-lg shadow-sm grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="overflow-hidden rounded-lg bg-gradient-purple px-4 py-3 shadow ">
            <dt className="truncate text-sm font-medium text-[#9F0]">
              Available for Withdraw
            </dt>
            <dd className="mt-1 text-xl font-semibold font-montserrat tracking-tight text-gray-100">
              {userData.balance_} BILLS
            </dd>
          </div>
          <div>
            <h3 className="text-center text-white">Choose your withdraw</h3>
            <div className="flex space-x-4 mt-4">
              <ModalWithdrawFrequent onClick={() => withdraw()} />
              <ModalInfoCompesated onClick={() => withdraw2()} />
            </div>
          </div>
        </div>

        <div className=" pb-12  lg:mt-0 mt-3">
          <div className="card-container items-center relative w-full max-w-7xl mx-auto px-4 rounded-lg    py-5 shadow-sm  grid grid-cols-1 gap-5 mt-10 mb-4 md:grid-cols-4 ">
            <div className="flex-1 min-w-0">
              <p className="text-md uppercase font-bold text-white">
                Your Referral
              </p>
              <div className="flex items-center mt-3">
                <div className="flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-auto">
                  <h4 className="text-xl font-bold text-white">
                    {" "}
                    {userData.referrerCountNumber} BILLS
                  </h4>
                </div>
              </div>
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-md uppercase font-bold text-white">
                Referral Rewards
              </p>
              <div className="flex items-center mt-3">
                <div className="flex w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-auto">
                  <h4 className="text-xl font-bold text-white">
                    {" "}
                    {userData.machineBalance} BILL
                  </h4>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start  md:col-span-1 md:mb-0">
              <CardRef />
            </div>
            <div className="lg:mt-5 flex flex-col items-center justify-start  md:col-span-1 md:mb-0">
              <ModalTableReferral />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-12 pb-12">
        <img alt="ASDFASDFASDF" src="/21bills.png" className="w-auto h-28" />
      </div>
    </>
  );
}

export default InvestNow;

const Claim = () => {
  const Claim = useClaim();

  const Onclick = async () => {
    const [load, contract] = await Claim;
    await contract.claim();
    console.log("Claim");
  };
  return (
    <button
      className="bg-[#99FF00] text-black border-[#99FF00] hover:bg-[#7acc00] rounded-md py-5 px-7 text-lg"
      onClick={() => Onclick()}
    >
      Claim
    </button>
  );
};

function EcosystemImage() {
  return (
    <div className="relative pt-4">
      <img
        src="/ecosystem_crypto.png"
        alt="Ecosystem"
        className="w-full h-auto rounded-b-lg "
      />

      {/* Invisible Link 1 - 21BNB */}
      <a
        href="https://21bnb.21billiondollars.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="absolute z-20 top-[40%] left-[6%] w-[15%] h-[15%] bg-transparent"
      >
        {/* Empty content as the link is invisible */}
      </a>

      {/* Invisible Link 2 - 21BILL Staking */}
      <a
        href="https://staking.21billboost.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="absolute top-[40%] right-[5%] w-[15%] h-[15%] bg-transparent"
      >
        {/* Empty content as the link is invisible */}
      </a>

      {/* Invisible Link 3 - 21BILL Boost */}
      <a
        href="https://www.21billboost.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="absolute bottom-[10%] left-[24%] w-[15%] h-[15%] bg-transparent"
      >
        {/* Empty content as the link is invisible */}
      </a>

      {/* Invisible Link 4 - 21LottoBills */}
      <a
        href="https://lottery.21billboost.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="absolute bottom-[10%] right-[23%] w-[15%] h-[15%] bg-transparent"
      >
        {/* Empty content as the link is invisible */}
      </a>
    </div>
  );
}