import { Fragment, useContext, useEffect, useState } from "react";
import Web3Context from "../context/Web3Context";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

function Navbar() {
  const solutions = [
    {
      name: "Buy Contract",
      href: "/invested",
    },
  ];

  const whitepapper = [
    {
      name: "English",
      href: "/whitepaper-en.pdf",
    },
    {
      name: "Spanish",
      href: "/whitepaper-es.pdf",
    },
    {
      name: "Russian",
      href: "/whitepaper-ru.pdf",
    },
    {
      name: "Português",
      href: "/whitepaper-port.pdf",
    },
    {
      name: "Korean - 한국인",
      href: "/whitepaper-kor.pdf",
    },
  ];

  const openPDF = () => {
    window.open("/document.pdf");
  };

  const openPDF2 = () => {
    window.open("/whitepaper-token.pdf");
  };
  const { accounts, connect, connectWallet,isLoaded } = useContext(Web3Context);

  const [acount_, setacount_] = useState("0x");

  useEffect(() => {
    console.log();
    const start = '0X...';
    const end = accounts?.slice(38);
    setacount_(`${start}...${end}`);

    return () => {};
  }, [accounts]);

  return (
      <>
        <Popover className=' bg-black z-30    fixed top-0 w-full border-b border-gray-900 transition duration-500 '>
          {({ open, close }) => (
              <>
                <div className='max-w-8xl mx-auto px-4 sm:px-6'>
                  <div className='flex justify-between items-center pt-3 pb-2 md:justify-start md:space-x-10'>
                    <div className='flex justify-start lg:w-0 lg:flex-1'>
                      <img alt='' src='/money-2.png' className='w-36 h-auto' />
                    </div>
                    <div className='-mr-2 -my-2 md:hidden'>
                      <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-black  '>
                        <span className='sr-only'>Open menu</span>
                        <MenuIcon className='h-6 w-6' aria-hidden='true' />
                      </Popover.Button>
                    </div>
                    <Popover.Group as='nav' className='hidden md:flex space-x-7'>
                      <a
                          href='https://www.facebook.com/21.billionpeople.oficial?mibextid=kFxxJD'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Facebook
                      </a>
                      <a
                          href='https://twitter.com/21billionpeople'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Twitter
                      </a>
                      <a
                          href='https://calculadora.21billboost.com/'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Calculator
                      </a>
                      <a
                          href='https://bscscan.com/address/0xfbc7460d6644192c483d0f291241928b60f09c08#code'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Token Contract
                      </a>
                      <a
                          href='https://bscscan.com/address/0x73a4b1718596b958640334fc0dd447c59Ca8C97D#code'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Contract
                      </a>
                      <a
                          href='https://t.me/+GPjaxd9Xycw2NTEx'
                          target={`_blank`}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Telegram
                      </a>
                      <button
                          type='button'
                          onClick={openPDF}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Business Presentation
                      </button>
                      <button
                          type='button'
                          onClick={openPDF2}
                          className='text-xs uppercase font-medium text-gray-100 hover:text-gray-200'
                      >
                        Whitepaper
                      </button>
                    </Popover.Group>
                    <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
                      <button
                          type="button"
                          onClick={()=> connectWallet()}
                          className='ml-8 text-white whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium bg-purple tlt'
                      >
                        {!isLoaded ? `Connect Wallet` : acount_}
                      </button>
                    </div>
                  </div>
                </div>

                <Transition
                    as={Fragment}
                    enter='duration-200 ease-out'
                    enterFrom='opacity-0 scale-95'
                    enterTo='opacity-100 scale-100'
                    leave='duration-100 ease-in'
                    leaveFrom='opacity-100 scale-100'
                    leaveTo='opacity-0 scale-95'
                >
                  <Popover.Panel
                      focus
                      className='absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
                  >
                    <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50'>
                      <div className='pt-5 pb-6 px-5'>
                        <div className='flex items-center justify-between'>
                          <img alt='' src='/money-2.png' className='w-28 h-auto' />
                          <div className='-mr-2'>
                            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-900  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'>
                              <span className='sr-only'>Close menu</span>
                              <XIcon className='h-6 w-6' aria-hidden='true' />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className='mt-6'>
                          <nav className='grid gap-y-8'>
                            <a
                                href='https://www.facebook.com/21.Dollars.Billals?mibextid=kFxxJD'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 p-3 uppercase flex items-center rounded-md text-gray-100 cursor-pointer'>
                            Facebook
                          </span>
                            </a>
                            <a
                                href='https://twitter.com/billtoken21'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 p-3 uppercase flex items-center rounded-md text-gray-100 cursor-pointer'>
                            Twitter
                          </span>
                            </a>
                            <a
                                href='https://calculadora.21billboost.com/'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 p-3 uppercase flex items-center rounded-md text-gray-100 cursor-pointer'>
                        Calculator
                          </span>
                            </a>
                            <a
                                href='https://bscscan.com/address/0xfbc7460d6644192c483d0f291241928b60f09c08#code'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 p-3 uppercase flex items-center rounded-md text-gray-100 cursor-pointer'>
                           Token Contract
                          </span>
                            </a>
                            <a
                                href='https://bscscan.com/address/0x73a4b1718596b958640334fc0dd447c59Ca8C97D#code'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 p-3 uppercase flex items-center rounded-md text-gray-100 cursor-pointer'>
                            Contract
                          </span>
                            </a>
                            <a
                                href='https://t.me/+GPjaxd9Xycw2NTEx'
                                target={`_blank`}
                                onClick={() => {
                                  close();
                                }}
                            >
                          <span className='-m-3 uppercase p-3 flex items-center rounded-md text-gray-100 cursor-pointer'>
                            Telegram
                          </span>
                            </a>
                            <button type='button' onClick={openPDF}>
                          <span className='-m-3 uppercase p-3 flex items-center rounded-md text-gray-100 cursor-pointer'>
                          Business Presentation
                          </span>
                            </button>
                            <button type='button' onClick={openPDF2}>
                          <span className='-m-3 uppercase p-3 flex items-center rounded-md text-gray-100 cursor-pointer'>
                          Whitepaper
                          </span>
                            </button>
                          </nav>
                        </div>
                      </div>
                      <div className='py-6 px-5 space-y-6'>
                        <div>
                          <button
                              type="button"
                              onClick={()=> connectWallet()}
                              className='w-full text-white flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium  bg-purple'
                          >
                            {!isLoaded ? `Connect Wallet` : acount_}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
          )}
        </Popover>
      </>
  );
}

export default Navbar;
